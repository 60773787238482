import { LinkFooterItem } from "./Layout";
import org_log from "../../assets/images/company_logo.webp";
import venture_logo from "../../assets/images/venture_logo.webp";
import n_cms from "../../assets/images/n_cms_logo.webp";
import n_dia from "../../assets/images/ndia_log.webp";
import pmi_badge from "../../assets/images/PMI_badge.svg";
import pmp_badge from "../../assets/images/PMP_badge.svg";
import usa_flag from "../../assets/images/usa_flag.svg";
import israel_flag from "../../assets/images/Israel_flag.svg";
import ukraine_flag from "../../assets/images/ukraine_flag.svg";
import gray_bar from "../../assets/images/footer_bar.svg";

export const Footer = () => {
  return (
    <footer
      className={`footer_wrap_mobile py-8 md:py-8 xl:py-8 md:footer_wrap px-8 md:px-0 mt-4 xl:mt-8 space-y-8`}
    >
      <div className="flex xl:flex-row max-xl:flex-col max-xl:space-y-5 xl:justify-center xl:space-x-5">
        <div className="xl:space-y-4 xl:w-[45vw]">
          <div className="flex justify-center ">
            <div className="flex justify-center">
              <img
                src={org_log}
                alt="Arsome Logo"
                title="Arsome Logo"
                className="w-[159px] xl:w-[200px]"
              />
            </div>
          </div>
          <div className="flex justify-center w-full">
            <p className="w-full md:w-[85vw] xl:w-full text-center md:text-sm xl:text-base max-md:mt-4 md:mt-4">
              ARSOME Technology develops
              tailor-made AR/VR training programs to better prepare soldiers and employees for dangerous,
              expensive, or time-sensitive workforce activities. Founded by a student and his professor, we
              exceed expectations, inspire the uninventive, and tirelessly outperform for clients and partners.
            </p>
          </div>
        </div>
        {/* <div className="xl:w-[7px] xl:bg-[#FFD700] max-xl:flex max-xl:justify-center">
          <div className="max-xl:w-[20vw] max-md:w-[150px] max-xl:h-[7px] max-xl:bg-[#FFD700]"></div>
        </div>
        <div className="xl:space-y-4 xl:w-[40vw]">
          <div className="flex justify-center ">
            <div className="flex justify-center">
              <img
                src={venture_logo}
                alt="ARSOME Ventures Logo"
                className="w-[159px] xl:w-[200px]"
              />
            </div>
          </div>
          <div className="flex justify-center w-full">
            <p className="w-full md:w-[85vw] xl:w-full text-center md:text-sm xl:text-base max-md:mt-4 md:mt-4">
              ARSOME Ventures, the investment arm of ARSOME Technology, makes
              strategic investments in early-stage companies with synergistic
              technology, expertise, and determination. ARSOME Ventures looks
              for awesome Founders building industry disruptive products. 
              <a
                href="https://arsomeventures.com"
                target="_blank"
                rel="noreferrer"
                className="font-bold underline cursor"
              >
                Learn more today.
              </a>
            </p>
          </div>
        </div> */}
      </div>
      <div className="flex justify-center w-full">
        <h3 className="text-center md:w-[85vw] text-xl font-semibold">
          ©{new Date().getFullYear()} All Rights Reserved. Powered by ARSOME
          Technology Group LLC.
        </h3>
      </div>
      <div className="flex justify-center w-full">
        <div className="bg-[#CFCFCF] h-[7px] md:w-[90vw] xl:w-[80vw] max-md:w-[80vw] rounded-lg"></div>
      </div>
      <div className="flex justify-center mt-3 space-x-5 xl:px-8 md:px-5 max-md:flex-wrap max-md:space-y-2">
        <div className="flex items-center">
          <img src={n_cms} alt="NCMS Logo" width={99} title="NCMS Logo" />
        </div>
        <div className="flex items-center">
          <img src={n_dia} alt="NDIA Logo" width={99} title="NDIA Logo" />
        </div>
        <div className="flex items-center">
          <img src={pmi_badge} alt="PMI Logo" width={50} title="PMI Logo" />
        </div>
        <div className="flex items-center">
          <img src={pmp_badge} alt="PMP Logo" width={50} title="PMP Logo" />
        </div>
        <img src={usa_flag} alt="USA Flag" title="USA Flag" />
        <img src={israel_flag} alt="Israel Flag" title="Israel Flag" />
        <img src={ukraine_flag} alt="Ukraine Flag" title="Ukraine Flag" />
      </div>
    </footer>
  );
};
