import { PropsWithChildren } from "react";

interface HeroWrapProps extends PropsWithChildren {
  children: React.ReactNode;
  className?: string;
  caption_cls?: string;
  tooltip_content?: string;
  tooltip_id?: string;
}

export const HeroWrap: React.FC<HeroWrapProps> = ({
  className,
  children,
  caption_cls,
  tooltip_content,
  tooltip_id,
}) => {
  return (
    <section
      className="flex justify-center"
      data-tooltip-id={tooltip_id}
      data-tooltip-content={tooltip_content}
      data-tooltip-place="bottom"
    >
      <div
        className={`flex justify-center ${className} md:flex md:py-3 md:items-center xl:py-10 xl:justify-center 2xl:h-[600px] md:w-full`}
      >
        <div
          className={`xl:w-[1100px] 2xl:w-[1440px] ${
            !caption_cls ? "flex justify-center mx-auto" : caption_cls
          } `}
        >
          <div
            className={
              "grid p-8 text-white rounded-xl md:bg_workforce xl:ms-0 md:p-4 xl:p-8 md:gap-4 xl:gap-8 md:w-[90vw] xl:w-[800px] 2xl:w-[880px] max-md:mx-auto"
            }
          >
            {children}
          </div>
        </div>
      </div>
    </section>
  );
};
